import React from 'react';
import styled from 'styled-components';
import { Container } from 'styled-bootstrap-grid';
import ContentHeader from './Content-header';
import { Grid, Cell, colors } from '../theme';
import { useFiles } from '../hooks/files';
import GatsbyImage from 'gatsby-image';

const SectionLayout = styled.section`
  position: relative;
  background-color: ${colors.gray.blogGray};
  border-color: ${colors.gray.blogGray};
  color: ${colors.gray.blogGray};
  padding-top: 10rem;
  padding-bottom: 10rem;
`;

const ParagraphContent = styled.li`
  text-align: justify;
  color: ${colors.gray.headerItemColor};
  margin-bottom: 1rem;
  ${(props) => props.english && 'direction: ltr'}
`;
const ParagraphWrapper = styled.div`
  padding: 3rem 0;
  margin-bottom: 3rem;
  ${(props) => props.english && 'direction: ltr'}
`;

const AwardsImg = styled(GatsbyImage)`
  max-width: 100%;
  height: auto;
`;

const AboutUsSecondSection = () => {
  const { award1, award2 } = useFiles();
  return (
    <SectionLayout>
      <Container>
        <ContentHeader title={'سوابق تحصیلی و فعالیت ها'} />
        <Grid rowGap={'1.5rem'} columnGap={'3.5rem'} rows={1} columns={2}>
          <Cell>
            <ParagraphWrapper>
              <ParagraphContent>
                دکترای پزشکی عمومی ؛ دانشگاه تهران ۱۳۷۲-۱۳۶۵
              </ParagraphContent>
              <ParagraphContent>
                دکترای تخصصی پزشکی؛ رشته رادیوتراپی اُنکولوژی دانشگاه علوم پزشکی
                تهران ۱۳۹۱ - ۱۳۸۸
              </ParagraphContent>
              <ParagraphContent>
                فلوشیپ فوق تخصصی طب تسکینی از دانشگاه علوم پزشکی تهران ۱۳۹۵ -
                ۱۳۹۴
              </ParagraphContent>
            </ParagraphWrapper>
          </Cell>
          <Cell>
            <ParagraphWrapper>
              <ParagraphContent>
                دانشگاه علوم پزشکی البرز 1391الی 1394 هیئت علمی ضریب کا بورد
                تخصصی رادیوتراپی انکولوژی
              </ParagraphContent>
              <ParagraphContent>
                دانشگاه علوم پزشکی البرز بیمارستان امام جعفر صادق و بیمارستان
                البرز تامین اجتماعی بعنوان متخصص رادیوتراپی انکولوژی و فلوشیپ تب
                تسکینی1394 تا حال حاضر
              </ParagraphContent>
            </ParagraphWrapper>
          </Cell>
        </Grid>
        <ContentHeader title={'مقالات علمی و پوستر چاپ شده'} />
        <Grid rowGap={'1.5rem'} columnGap={'3.5rem'} rows={1} columns={1}>
          <Cell>
            <ParagraphWrapper english>
              <ParagraphContent english>
                Firest application of total skin electron beam therapy for
                mycosis fungoids in Iran
              </ParagraphContent>
              <ParagraphContent english>
                Step-and-shoot versus compensator-based IMRT : calculation and
                comparision of integral dose in non-tumoral and target organs in
                prostate cancer
              </ParagraphContent>
              <ParagraphContent english>
                Dosimetric study of photon small fields using polymer gel and
                Gafchromic EBT films
              </ParagraphContent>
              <ParagraphContent english>
                The calculation and comparison of integral dose for rectum,
                bladder, right and left femur heads in two methods of prostate
                cancer radiotherapy :S.A.S IMRT vs. 3D CRT
              </ParagraphContent>
            </ParagraphWrapper>
          </Cell>
        </Grid>

        <Grid rowGap={'1.5rem'} rows={1} columns={4}>
          <Cell>
            <AwardsImg fluid={award1}></AwardsImg>
          </Cell>
          <Cell>
            <AwardsImg fluid={award2}></AwardsImg>
          </Cell>
        </Grid>
      </Container>
    </SectionLayout>
  );
};

export default AboutUsSecondSection;
