import React from 'react';
import styled from 'styled-components';
import { Container } from 'styled-bootstrap-grid';
import { Grid, Cell, colors, device } from '../theme';
import TeaserBox from './Teaser-box';
import SeperetorBottom from './Seperator-bottom';
import { useFiles } from '../hooks/files';

const SectionLayout = styled.section`
  position: relative;
  background-color: ${colors.gray.headerBackground};
  border-color: ${colors.gray.headerBackground};
  color: ${colors.gray.headerBackground};
  padding-top: 5rem;
  padding-bottom: 5rem;

  @media ${device.laptop} {
    padding-top: 15rem;
    padding-bottom: 10rem;
  }
`;

const ParagraphContent = styled.p`
  text-align: justify;
  color: ${colors.gray.headerItemColor};
`;
const ParagraphWrapper = styled.div`
  padding: 2rem 0;
`;

const AboutUsMainSection = () => {
  const { bg2 } = useFiles();
  return (
    <SectionLayout>
      <Container>
        <Grid
          rowGap={'1.5rem'}
          columnGap={'3.5rem'}
          rows={1}
          columns={'30% 70%'}
        >
          <Cell>
            <TeaserBox title={'دکتر محمود سامعی'} img={bg2}></TeaserBox>
          </Cell>
          <Cell>
            <ParagraphWrapper>
              <ParagraphContent>
                دکتر محمود سامعی متخصص رادیوتراپی و کلینیکال انکولوژی، پرتو
                درمانی و شیمی درمانی تومورها، پیشگیری، مشاوره و درمان می باشد.
              </ParagraphContent>
              <ParagraphContent>
                دکتر محمود سامعی دارای رتبه عالی بورد تخصصی بطور متمرکز به پژوهش
                در تومورهای مختلف مخصوصا تومورهای دستگاه گوارش، تومورهای سر و
                گردن، تومورهای پروستات، تومورهای رحم و تومورهای پستان پرداخته
                است.
              </ParagraphContent>
              <ParagraphContent>
                دکتر محمود سامعی، متخصص رادیوتراپی و کلینیکال انکولوژی با دانش
                بالا و استفاده از تجهیزات روز دنیا قادر به تشخیص بیماری و آماده
                ارائه‌ خدمات درمانی به شما عزیزان می باشد.
              </ParagraphContent>
            </ParagraphWrapper>
          </Cell>
        </Grid>
      </Container>
      <SeperetorBottom />
    </SectionLayout>
  );
};

export default AboutUsMainSection;
