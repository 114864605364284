import React from 'react';
import Layout from '../components/Layout';
import HeadPanel from '../components/HeadPanel';
import SeperatorTopFull from '../components/Seperator-top-full';
import AboutUsMainSection from '../components/About-main-section';
import AboutUsSecondSection from '../components/About-second-section';
import { useFiles } from '../hooks/files';

const AboutUsPage = () => {
  const { slide3Jpeg } = useFiles();

  return (
    <Layout>
      <div>
        <HeadPanel
          img={slide3Jpeg}
          title={'دکتر محمود سامعی'}
          subtitle={'متخصص رادیوتراپی و کلینیکال انکولوژی'}
        ></HeadPanel>
        <SeperatorTopFull />
        <AboutUsMainSection />
        <AboutUsSecondSection />
      </div>
    </Layout>
  );
};

export default AboutUsPage;
